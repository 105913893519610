export default {
  pubstack: '//boot.pbstck.com/v1/tag/b9725a8b-9a93-4740-aaba-5e304966592e',
  indexWrapper: '//js-sec.indexww.com/ht/p/189918-175304787377060.js',
  smartConsentlessData: 'https://storage.googleapis.com/prd-lemonde-io-wrapper/mapping/smart-consentless-lemonde.json',
  smartAdCallConfig: {},
  excludedFormatsHB: ['inimage_seed'],
  videoClassName: 'js_player',
  instreamIndex: {
    siteId: '543370',
    size: [640, 480],
    video: {
      mimes: [
        'video/mp4',
        'video/webm',
        'video/mpg',
        'application/javascript',
      ],
      startdelay: 0,
      minduration: 5,
      maxduration: 180,
      protocols: [2, 3, 5, 6],
      api: [1, 2],
    },
  },
};
