export default {
  pubstack: '//boot.pbstck.com/v1/tag/f4499aff-9d2a-41d1-ad61-a185b016abd9',
  indexWrapper: '//js-sec.indexww.com/ht/p/189918-157725017542452.js',
  smartConsentlessData: 'https://storage.googleapis.com/prd-lemonde-io-wrapper/mapping/smart-consentless-coniugazione-it.json',
  smartAdCallConfig: {},
  excludedFormatsHB: ['inimage_seed'],
  // Smart
  smart: {
    coniugazione: {
      siteId: 278833,
      pageId: 1262788,
    },
  },
  // Sublime
  sublime: {
    coniugazione: {
      formats: {
        banniere_haute: { zoneId: 32118 },
        cover: { zoneId: 32997 },
      },
    },
  },
  // Teads
  teads: {
    coniugazione: { pageId: 118572, placementId: 128648 },
  },
};
