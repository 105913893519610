/* eslint-disable arrow-body-style, arrow-parens */
/* global window, document, fetch, sas, MutationObserver  */
import configuration from '../lib/config';
import { log } from '../lib/utils';

const defaultAdUnit = () => {
  if (typeof configuration.smartAdCallConfig[configuration.adUnit.slice(11)] !== 'undefined') {
    return configuration.smartAdCallConfig[configuration.adUnit.slice(11)];
  }
  return configuration.smartAdCallConfig.default;
};

const pushRenderSmartAdUnit = () => {
  const slotIds = document.querySelectorAll(configuration.slots);
  slotIds.forEach((slotId) => {
    sas.cmd.push(() => {
      log('formatsAdUnit', slotId.id);
      sas.render(slotId.id);
    });
  });
};

const removeUnloadedClassName = () => {
  const dfpSlots = document.querySelectorAll(configuration.slots);
  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      if (mutation.addedNodes.length > 0 && mutation.target.className.includes('dfp-unloaded')) {
        const adsLoaded = document.getElementById(mutation.target.id);
        adsLoaded.classList.remove(configuration.unloadedClassName);
        log(`${mutation.target.id} -> remove class ${configuration.unloadedClassName}`);
      }
    });
  });
  dfpSlots.forEach((dfpSlot) => {
    dfpSlot.classList.remove(configuration.loadingClassName);
    observer.observe(dfpSlot, { childList: true, subtree: true });
  });
};

export const getSmartConsentlessData = () => new Promise((resolve) => {
  fetch(configuration.smartConsentlessData)
    .then((res) => res.json())
    .then((data) => {
      configuration.smartAdCallConfig = data.smartAdCallConfig;
      resolve();
    })
    .catch((err) => resolve(err));
});

export const renameInreadId = () => {
  const inreads = document.querySelectorAll('[data-format="inread"]');
  inreads.forEach((inread, index) => {
    if (index === 0) {
      inread.setAttribute('id', 'inread');
    } else {
      inread.setAttribute('id', `inread_${index + 1}`);
    }
  });
};

export const initConfigSmart = () => {
  window.sas = window.sas || {};
  window.sas.cmd = window.sas.cmd || [];
  sas.cmd.push(() => {
    sas.setup({ networkid: 3937, domain: '//diff.smartadserver.com', async: true });
  });
};

export const executeSmartDecision = () => {
  sas.callSmart = () => {
    log('Call to Smart');

    const smartConfig = defaultAdUnit();
    smartConfig.target = Object.keys(configuration.targets).map((index) => {
      if (!Array.isArray(configuration.targets[index])) {
        return `${index}=${configuration.targets[index]}`;
      }
      return `${index}=${configuration.targets[index].map((value) => value)}`;
    }).join(';');

    sas.cmd.push(() => {
      sas.call('onecall', smartConfig);
    });
    pushRenderSmartAdUnit();
  };
  sas.callSmart();
  removeUnloadedClassName();
};
