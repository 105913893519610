/* global document */

const doToggle = (toggle, cookieDebugName) => {
  const domain = `.${document.location.hostname.split('.').slice(1).join('.')}`;
  const expiresYear = (toggle === false) ? 1999 : 2099;

  const parts = [
    `${cookieDebugName}=true`,
    `expires=Thu, 18 Dec ${expiresYear} 12:00:00 UTC;`,
    'path=/',
    `domain=${domain}`,
  ];

  document.cookie = parts.join(';');
  document.location.reload();
};

const toggleStaging = (toggle) => doToggle(toggle, 'glmaw_staging');
const toggleConsentlessStaging = (toggle) => doToggle(toggle, 'consentless_staging');

const isStaging = (cookieDebugName) => {
  const cookie = `;${document.cookie}`.match(`;\\s*${cookieDebugName}=([^;]+)`);

  return cookie !== null;
};

export {
  isStaging,
  toggleStaging,
  toggleConsentlessStaging,
};
