export default {
  pubstack: '//boot.pbstck.com/v1/tag/fe39d2b3-a649-4e0b-9ffe-dd678b8ca58e',
  indexWrapper: '//js-sec.indexww.com/ht/p/189918-206103529318393.js',
  smartConsentlessData: 'https://storage.googleapis.com/prd-lemonde-io-wrapper/mapping/smart-consentless-conjugacao-pt.json',
  smartAdCallConfig: {},
  excludedFormatsHB: ['inimage_seed'],
  // Smart
  smart: {
    conjugacao: {
      siteId: 278833,
      pageId: 1262787,
    },
  },
  // Teads
  teads: {
    conjugacao: { pageId: 118571, placementId: 128647 },
  },
};
