export default {
  pubstack: '//boot.pbstck.com/v1/tag/8b306fc8-029f-4764-a4e1-7c9bce891843',
  smartConsentlessData: 'https://storage.googleapis.com/prd-lemonde-io-wrapper/mapping/smart-consentless-konjugation-de.json',
  smartAdCallConfig: {},
  excludedFormatsHB: ['inimage_seed'],
  refresh: {
    inViewPercentage: 50,
    maxInactivity: 90000,
    minDisplayTime: 15000,
    minSinceActive: 5000,
    minSinceViewed: 5000,
    respectRoadblocks: true,
    tick: 500,
    excludedFormats: [
      'banniere_haute',
      'cover',
      'dhtml',
      'habillage',
      'parallaxe',
      'partenaire_middle',
      'partenaire_bottom',
    ],
  },
  // Smart
  smart: {
    konjugation: {
      siteId: 278833,
      pageId: 1238161,
    },
  },
  // Sublime
  sublime: {
    konjugation: {
      formats: {
        banniere_haute: { zoneId: 31848 },
        cover: { zoneId: 31847 },
      },
    },
  },
  // Teads
  teads: {
    konjugation: { pageId: 116964, placementId: 126958 },
  },
  // Rubicon
  rubicon: {
    konjugation: {
      siteId: 324544,
      placements: {
        banniere_haute: { tag: 1683734 },
        pave_haut: { tag: 1683738 },
        pave_milieu: { tag: 1683740 },
      },
    },
  },
  indexWrapper: '//js-sec.indexww.com/ht/p/189918-250081517562277.js',
};
