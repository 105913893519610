/* global document */
import * as deviceDetect from './device';

export const device = () => {
  if (deviceDetect.phone !== false) {
    return 'mobile';
  }

  if (deviceDetect.tablet !== false) {
    return 'tablet';
  }

  return 'desktop';
};

export const site = (siteList, url = document.location.hostname) => siteList
  .reduce((accumulator, given) => {
    const regex = new RegExp(given.regex);

    if (regex.test(url)) {
      accumulator = given.name;
    }

    return accumulator;
  }, '') || false;
