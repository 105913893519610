export default {
  pubstack: '//boot.pbstck.com/v1/tag/d9ba314d-246c-48be-9d6c-56115639c58a',
  indexWrapper: '//js-sec.indexww.com/ht/p/189918-8191436376685.js',
  smartConsentlessData: 'https://storage.googleapis.com/prd-lemonde-io-wrapper/mapping/smart-consentless-conjugacion-es.json',
  smartAdCallConfig: {},
  excludedFormatsHB: ['inimage_seed'],
  formats: {
    cover: {
      desktop: 'out-of-page',
      tablet: 'out-of-page',
      mobile: 'out-of-page',
    },
  },
  // Smart
  smart: {
    conjugacion: {
      siteId: 278833,
      pageId: 1262789,
    },
  },
  // Sublime
  sublime: {
    conjugacion: {
      formats: {
        banniere_haute: { zoneId: 32116 },
        cover: { zoneId: 32115 },
      },
    },
  },
  // Teads
  teads: {
    conjugacion: { pageId: 118573, placementId: 128649 },
  },
};
