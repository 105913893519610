import conjugaison from './conjugaison-fr';
import courrier from './courrier';
import huffpost from './huffpost';
import konjugation from './konjugation-de';
import lemonde from './lemonde';
import lobs from './lobs';
import telerama from './telerama';
import theconjugation from './conjugation-en';
import conjugacion from './conjugacion-es';
import coniugazione from './coniugazione-it';
import conjugacao from './conjugacao-de-verbos-pt';

export default () => {
  const sites = [{
    name: 'lemonde',
    regex: '(https?://)?.*lemonde.fr',
    specificConfig: lemonde,
  }, {
    name: 'lobs',
    regex: '(https?://)?.*nouvelobs.com',
    specificConfig: lobs,
  }, {
    name: 'conjugaison',
    regex: '(https?://)?la-conjugaison.nouvelobs.com',
    specificConfig: conjugaison,
  }, {
    name: 'telerama',
    regex: '(https?://)?.*telerama.fr',
    specificConfig: telerama,
  }, {
    name: 'courrier',
    regex: '(https?://)?.*courrierinternational.com',
    specificConfig: courrier,
  }, {
    name: 'huffpost',
    regex: '(https?://)?.*huffingtonpost.fr',
    specificConfig: huffpost,
  }, {
    name: 'konjugation',
    regex: '(https?://)?.*die-konjugation.de',
    specificConfig: konjugation,
  }, {
    name: 'theconjugation',
    regex: '(https?://)?.*the-conjugation.com',
    specificConfig: theconjugation,
  }, {
    name: 'conjugacion',
    regex: '(https?://)?.*conjugacion.es',
    specificConfig: conjugacion,
  }, {
    name: 'coniugazione',
    regex: '(https?://)?.*coniugazione.it',
    specificConfig: coniugazione,
  }, {
    name: 'conjugacao',
    regex: '(https?://)?.*conjugacao-de-verbos.com',
    specificConfig: conjugacao,
  },
  ];

  return {
    getSpecificConfig(actualSite) {
      let configValue = {};
      sites.forEach((element) => {
        if (element.name === actualSite && element.specificConfig) {
          configValue = { ...element.specificConfig };
        }
      });
      return configValue;
    },
    getSites() {
      return sites;
    },
  };
};
