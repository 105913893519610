export default {
  pubstack: '//boot.pbstck.com/v1/tag/fb0126f3-23be-4a48-968e-45c0770f17da',
  indexWrapper: '//js-sec.indexww.com/ht/p/189918-206081011822296.js',
  smartConsentlessData: 'https://storage.googleapis.com/prd-lemonde-io-wrapper/mapping/smart-consentless-huffpost.json',
  smartAdCallConfig: {},
  excludedFormatsHB: [],
  formats: {
    inimage_seed: {
      desktop: 'out-of-page',
      tablet: 'out-of-page',
      mobile: 'out-of-page',
    },
    inread: {
      desktop: [
        'fluid',
        [2, 2],
        [300, 250],
        [630, 355],
      ],
      tablet: [
        'fluid',
        [2, 2],
        [300, 250],
      ],
      mobile: [
        'fluid',
        [2, 2],
        [300, 250],
      ],
    },
    native_feed: {
      desktop: [
        'fluid',
        [1, 1],
        [770, 200],
      ],
      tablet: [
        'fluid',
        [1, 1],
        [770, 200],
      ],
      mobile: [
        'fluid',
        [1, 1],
        [300, 250],
      ],
    },
    native_feed_bis: {
      desktop: [
        'fluid',
        [1, 1],
        [770, 200],
      ],
      tablet: [
        'fluid',
        [1, 1],
        [770, 200],
      ],
      mobile: [
        'fluid',
        [1, 1],
        [300, 250],
      ],
    },
    native_multiplex_coldroite: {
      desktop: [
        'fluid',
        [1, 1],
        [300, 250],
      ],
      tablet: [
        'fluid',
        [1, 1],
        [300, 250],
      ],
      mobile: [
        'fluid',
        [1, 1],
        [300, 250],
      ],
    },
  },
};
