/* global STAGING_BUILD */
import { loadScript, log } from './lib/utils';
import { isStaging } from './modules/debug';
import configuration from './lib/config';
import {
  getSmartConsentlessData,
  renameInreadId,
  initConfigSmart,
  executeSmartDecision,
} from './modules/smartDecisionScript';

const execute = () => new Promise((resolve) => {
  getSmartConsentlessData()
    .then(() => {
      loadScript(configuration.smartConsentlessScript).then(resolve);
      renameInreadId();
      initConfigSmart();
      executeSmartDecision();
    })
    .catch((err) => {
      log(err);
      resolve();
    });
});

export const init = () => {
  if (isStaging('consentless_staging') === true && STAGING_BUILD === false) {
    log('Load staging consentless script');
    loadScript('https://wrapper.lemde.fr/consentless-staging.js');
  } else {
    log('init consentless script');
    execute();
  }
};
