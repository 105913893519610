export default {
  pubstack: '//boot.pbstck.com/v1/tag/b14d687b-bcaf-4e7f-b6ad-f1e4c2e84ffd',
  indexWrapper: '//js-sec.indexww.com/ht/p/189918-267653662438059.js',
  smartConsentlessData: 'https://storage.googleapis.com/prd-lemonde-io-wrapper/mapping/smart-consentless-telerama.json',
  smartAdCallConfig: {},
  excludedFormatsHB: ['inimage_seed'],
  formats: {
    pave_haut: {
      desktop: [
        [160, 600],
        [300, 250],
        [300, 600],
      ],
      tablet: [
        [160, 600],
        [300, 250],
        [300, 600],
      ],
    },
    pave_milieu: {
      desktop: [
        [160, 600],
        [300, 250],
        [300, 600],
      ],
      tablet: [
        [160, 600],
        [300, 250],
        [300, 600],
      ],
    },
  },
};
