export default {
  pubstack: '//boot.pbstck.com/v1/tag/bf8c220b-f8cb-4bf2-9f86-8b0a5d6d3e77',
  indexWrapper: '//js-sec.indexww.com/ht/p/189918-190687849096589.js',
  smartConsentlessData: 'https://storage.googleapis.com/prd-lemonde-io-wrapper/mapping/smart-consentless-conjugaison.json',
  smartAdCallConfig: {},
  excludedFormatsHB: ['inimage_seed'],
  formats: {
    cover: {
      desktop: 'out-of-page',
      tablet: 'out-of-page',
      mobile: 'out-of-page',
    },
  },
  // Smart
  smart: {
    lobs: {
      siteId: 278833,
      pageId: 1302281,
    },
  },
  // Sublime
  sublime: {
    lobs: {
      formats: {
        banniere_haute: { zoneId: 31902 },
        cover: { zoneId: 31901 },
      },
    },
  },
  // Teads
  teads: {
    lobs: { pageId: 117825, placementId: 127924 },
  },
  site: 'lobs', // for non specific config, taking lobs settings
};
