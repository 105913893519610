export default {
  pubstack: '//boot.pbstck.com/v1/tag/6c9d91b6-476b-4385-b76a-cbdcba750b31',
  indexWrapper: '//js-sec.indexww.com/ht/p/189918-190687849096589.js',
  smartConsentlessData: 'https://storage.googleapis.com/prd-lemonde-io-wrapper/mapping/smart-consentless-lobs.json',
  smartAdCallConfig: {},
  excludedFormatsHB: [],
  formats: {
    inimage_seed: {
      desktop: 'out-of-page',
      tablet: 'out-of-page',
      mobile: 'out-of-page',
    },
  },
};
